<template>
    <div
        class="person-card hover-ef"
        @click="$emit('select', {id: person.id})"
    >
        <div class="person-card__image">
            <img :src="person.image" alt="" />
        </div>
    </div>
</template>

<script>
import numberFormat from '@/core/filters/number-format';
import {mapGetters} from 'vuex';

export default {
    name: 'PersonCard',
    filters: {
        numberFormat,
    },
    props: {
        person: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('quiz', [
            'stepFund',
        ]),
    },
};
</script>
