<template>
    <div class="">
        <h1 class="home-page__title">
            чтобы начать игру - выберите персонажа
        </h1>
        <transition-group tag="div" class="home-page__content" name="scale-in">
            <PersonCard
                v-show="isLoaded"
                v-for="person in persons"
                :key="person.id"
                :person="person"
                @select="updatePerson"
            />
        </transition-group>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PersonCard from '@/components/home-page/PersonCard';

export default {
    components: {
        PersonCard,
    },
    data() {
        return {
            isLoaded: false,
        };
    },
    computed: {
        ...mapGetters('persons', [
            'persons',
        ]),
    },
    mounted() {
        this.isLoaded = true;
    },
    methods: {
        ...mapActions('persons', [
            'selectPerson',
        ]),
        async updatePerson({id}) {
            try {
                const person = await this.selectPerson(id);
                if (person !== null) {
                    this.$router.push({name: 'PersonType'});
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
